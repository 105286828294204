import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useAppDispatch } from "../../../../store/hooks";
import { getUpComingReturns } from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import {
  formatDatePipe,
  formateIds,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { toast } from "react-toastify";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useSelector } from "react-redux";

function UpcomingReturns() {
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const upcomingReturnsCols = (JSON.parse(localStorage.getItem('upcomingReturnsCol') || '[]'));
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "UpcomingReturn.csv",
    URL: "Reports/UpcomingReturn",
    filterOptions: [],
  });

  const columnItems = [
    {
      headerName: "Customer ID",
      field: "customerId",
      headerTooltip: "Customer ID",
      tooltipShowDelay: 0,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: upcomingReturnsCols.includes("customerId"),
      comparator: customComparator,
    },
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      field: "orderNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: upcomingReturnsCols.includes("orderNumber"),
      comparator: customComparator,
    },
    {
      headerName: "Service Offering",
      tooltipField: "serviceOffering",
      headerTooltip: "Service Offering",
      tooltipShowDelay: 0,
      field: "serviceOffering",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: upcomingReturnsCols.includes("serviceOffering"),
      comparator: customComparator,
    },

    {
      headerName: "Date Scheduled/Requested",
      headerTooltip: "Date Scheduled/Requested",
      tooltipShowDelay: 0,
      field: "dateScheduledRequested",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      menuTabs: ["filterMenuTab"],
      hide: upcomingReturnsCols.includes("dateScheduledRequested"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Actual Pickup Date",
      headerTooltip: "Actual Pickup Date",
      tooltipShowDelay: 0,
      field: "actualPickupDate",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      menuTabs: ["filterMenuTab"],
      hide: upcomingReturnsCols.includes("actualPickupDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
  ];
  const [colDefs, setColDefs] = useState(columnItems);
  function customComparator() {
    return 0; //means no comparing and no sorting
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    const cols: any = [];

    columnValues.forEach((value: any) => {
      if (value?.hide === true)
        cols.push(value.field);
    });

    localStorage.setItem("upcomingReturnsCol", JSON.stringify(cols));
    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();
    if (gridRef.current) {
      // gridRef.current.api.autoSizeAllColumns();
    }
  };

  const UpComingReturns = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              PageSize: gridData.pageSize,
              SortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              SortField: gridData.sortField,
              IsForExcel: gridData.isForExcel,
              GlobalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getUpComingReturns(data)).then((result: any) => {
              if (result?.payload?.data?.upcomingReturn?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.upcomingReturn,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData(
                "totalAmount",
                CurrencyConverter.format(result?.payload?.data?.totalAmount)
              );
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        UpComingReturns();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      UpComingReturns();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);



  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  }
  return (
    <Container fluid>
      <PageTitle
        name="Upcoming Service Events"
        buttonName="Back"
        routePage="/reports/view-all"
      ></PageTitle>
      <div className="content_body grid-reset">
        <GridComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          shortOrder={gridData.sortOrder}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </div>
    </Container>
  );
}
export default UpcomingReturns;
