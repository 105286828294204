import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import GridTotalCountHeader from "../../../../components/grid/gridTotalCountHeader";
import { getSFGSummary } from "../../../../store/api/apiSlice";
import { useAppDispatch } from "../../../../store/hooks";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import {
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useNavigate } from "react-router-dom";

function ServiceFeeGuarantees() {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const [gridApi, setGridApi] = useState<any>(null);
  const sfgCols = (JSON.parse(localStorage.getItem('sfgCol') || '[]'));
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "SFGSummary.csv",
    URL: "Reports/SFGSummary",
    filterOptions: [],
  });
  const columnitems = [
    {
      headerName: "Customer ID",
      headerTooltip: "Customer ID",
      tooltipShowDelay: 0,
      field: "customerId",
      hide: sfgCols.includes("customerId"),
      comparator: customComparator,
      cellStyle: { cursor: "pointer" },
      cellRenderer: function (params: any) {
        return (
          <div
            className="text-link"
            onClick={(e) => {
              localStorage.setItem("SFGDetailRow", JSON.stringify(params.data));
              navigate(
                "/reports/servicefeeguarantees-details/" +
                  params.data.customerId
              );
            }}
          >
            {params.data.customerId}
          </div>
        );
      },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      field: "orderNumber",
      hide: sfgCols.includes("orderNumber"),
      comparator: customComparator,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Process Date",
      headerTooltip: "Process Date",
      tooltipShowDelay: 0,
      field: "processDate",
      hide: sfgCols.includes("processDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
    },
    {
      headerName: "Refund Date",
      headerTooltip: "Refund Date",
      tooltipShowDelay: 0,
      field: "refundDate",
      hide: sfgCols.includes("refundDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
    },
    {
      headerName: "ERV",
      headerTooltip: "ERV",
      tooltipShowDelay: 0,
      field: "erv",
      hide: sfgCols.includes("erv"),
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "ARV",
      headerTooltip: "ARV",
      tooltipShowDelay: 0,
      field: "arv",
      hide: sfgCols.includes("arv"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Disqualified Erv",
      headerTooltip: "Disqualified Erv",
      tooltipShowDelay: 0,
      field: "disqualifiedErv",
      hide: sfgCols.includes("disqualifiedErv"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Unrecovered Credits",
      headerTooltip: "Unrecovered Credits",
      tooltipShowDelay: 0,
      field: "unrecoveredCredits",
      hide: sfgCols.includes("unrecoveredCredits"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Service Fee Rate",
      headerTooltip: "Service Fee Rate",
      tooltipShowDelay: 0,
      field: "serviceFeeRate",
      hide: sfgCols.includes("serviceFeeRate"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Service Fee Refund",
      headerTooltip: "Service Fee Refund",
      tooltipShowDelay: 0,
      field: "serviceFeeRefund",
      hide: sfgCols.includes("serviceFeeRefund"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
  ];
  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }
  const [colDefs, setColDefs] = useState(columnitems);

  const onGridReady = (params: any) => {
    setTimeout(() => {
      params.api.autoSizeAllColumns();
    }, 100);

    setGridApi(params.api);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    const cols: any = [];

    columnValues.forEach((value: any) => {
      if (value?.hide === true)
        cols.push(value.field);
    });

    localStorage.setItem("sfgCol", JSON.stringify(cols));
    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();
  };

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  const getSummary = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageSize: gridData.pageSize,
              PageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              GlobalSearch: gridData.globalSearch,
              IsForExcel: gridData.isForExcel,
              SortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getSFGSummary(data)).then((result: any) => {
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData(
                "totalAmount",
                CurrencyConverter.format(result?.payload?.data?.totalAmount)
              );
              updateGridData("rows", result?.payload?.data?.sfGs);
              if (result?.payload?.data?.sfGs?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.sfGs,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        getSummary();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      getSummary();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };

  const onExport = (e: any) => {
    if (gridData.totalRows > 100000) {
      toast.warning(
        "The system limits exports to a maximum of 100,000 records"
      );
    } else {
      updateGridData("isForExcel", true);
    }
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };
  return (
    <Container fluid>
      <PageTitle
        name="Service Fee Guarantee Summary"
        buttonName="Back"
        routePage="/reports/view-all"
      ></PageTitle>
      <div className="content_body">
        <GridTotalCountHeader
          title="Total Service Fee Refunds"
          count={gridData.totalAmount}
          description="Refunds issued for the summary of selected records."
        ></GridTotalCountHeader>
        <GridComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
          autoSizeStrategy={{
            type: "fitCellContents",
            colIds: ["erv", "arv"],
          }}
        ></GridComponent>
      </div>
    </Container>
  );
}
export default ServiceFeeGuarantees;
