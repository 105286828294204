import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useAppDispatch } from "../../../../store/hooks";
import { getTrackShipmentDetails } from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import {
  formatDatePipe,
  formateIds,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { toast } from "react-toastify";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useSelector } from "react-redux";

function TrackShipment() {
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const [gridApi, setGridApi] = useState<any>(null);
  const dispatch = useAppDispatch();
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const colFromStorage = localStorage.getItem('trackShipmentCol');
  const trackShipmentCols = (JSON.parse(colFromStorage || '[]'));
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "TrackShipment.csv",
    URL: "Reports/TrackShipment",
    filterOptions: [],
  });

  const columnItems = [
    {
      headerName: "Tracking#",
      field: "trackingNumber",
      headerTooltip: "Tracking#",
      tooltipShowDelay: 0,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: trackShipmentCols.includes("trackingNumber"),
      comparator: customComparator,
      minWidth: 180,
    },
    {
      headerName: "Customer ID",
      field: "customerId",
      headerTooltip: "Customer ID",
      tooltipShowDelay: 0,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: trackShipmentCols.includes("customerId"),
      comparator: customComparator,
    },
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      field: "orderNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: trackShipmentCols.includes("orderNumber"),
      comparator: customComparator,
    },
    {
      headerName: "Service Offering",
      headerTooltip: "Service Offering",
      tooltipField: "serviceOffering",
      tooltipShowDelay: 0,
      field: "serviceOffering",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: trackShipmentCols.includes("serviceOffering"),
      comparator: customComparator,
    },
    {
      headerName: "Collection Option",
      headerTooltip: "Collection Option",
      tooltipShowDelay: 0,
      field: "collectionOption",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: trackShipmentCols.includes("collectionOption"),
      comparator: customComparator,
    },
    {
      headerName: "Pickup Date",
      field: "pickupDate",
      headerTooltip: "Pickup Date",
      tooltipShowDelay: 0,
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      menuTabs: ["filterMenuTab"],
      hide: trackShipmentCols.includes("pickupDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Carrier",
      headerTooltip: "Carrier",
      tooltipShowDelay: 0,
      field: "carrier",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: trackShipmentCols.includes("carrier"),
      comparator: customComparator,
      cellRenderer: function (params: any) {
        if (params.value !== '0' || params.value !== 0) {
          return (
            <a
              target="_blank"
              href={
                "https://www.ups.com/track?track=yes&trackNums=" +
                params.data.trackingNumber
              }
              rel="noreferrer"
            >
              {params.value}
            </a>
          );
        } else {
          return (<> </>)
        }
      },
    },
    {
      headerName: "Received Date",
      field: "receivedDate",
      headerTooltip: "Received Date",
      tooltipShowDelay: 0,
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      menuTabs: ["filterMenuTab"],
      hide: trackShipmentCols.includes("receivedDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Status",
      field: "status",
      headerTooltip: "Status",
      tooltipShowDelay: 0,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: trackShipmentCols.includes("status"),
      cellRenderer: function (params: any) {
        let tmpClass: any = "";
        if (
          params.data.status === "Received" ||
          params.data.status === "Processed"
        ) {
          tmpClass = "created";
        } else if (
          params.data.status === "Void" ||
          params.data.status === "Destroyed"
        ) {
          tmpClass = "destroyed";
        } else if (params.data.status === "Pending Receipt") {
          tmpClass = "pending";
        } else {
          tmpClass = "default-status";
        }
        return (
          <div className="status">
            <div className={tmpClass}>{params.data.status}</div>
          </div>
        );
      },
    },
  ];

  const localdata: any = localStorage.getItem("trackShipment");
  const [colDefs, setColDefs] = useState(columnItems);
  function customComparator() {
    return 0; //means no comparing and no sorting
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    const cols: any = [];

    columnValues.forEach((value: any) => {
      if (value?.hide === true)
        cols.push(value.field);
    });

    localStorage.setItem("trackShipmentCol", JSON.stringify(cols));
    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();
    if (gridRef.current) {
      gridRef.current.api.autoSizeAllColumns();
    }
  };

  const TrackShipment = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              PageSize: gridData.pageSize,
              SortField: gridData.sortField,
              SortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              IsForExcel: gridData.isForExcel,
              GlobalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getTrackShipmentDetails(data)).then((result: any) => {
              if (result?.payload?.data?.trackShipment?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.trackShipment,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }

              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData(
                "totalAmount",
                CurrencyConverter.format(result?.payload?.data?.totalAmount)
              );
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        TrackShipment();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      TrackShipment();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);



  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  }
  return (
    <Container fluid>
      <PageTitle
        name="Track a Shipment"
        buttonName="Back"
        routePage="/reports/view-all"
      ></PageTitle>
      <div className="content_body grid-reset">
        <GridComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          shortOrder={gridData.sortOrder}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </div>
    </Container>
  );
}
export default TrackShipment;
