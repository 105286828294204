import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect, useCallback } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import GridTotalCountHeader from "../../../../components/grid/gridTotalCountHeader";
import { useAppDispatch } from "../../../../store/hooks";
import { getTransactionSummary } from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import { useNavigate } from "react-router-dom";
import {
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useSelector } from "react-redux";
import { usePermission } from "../../../../helpers/hooks/usePermissions";
import { RowHeightParams } from "ag-grid-enterprise";
import { toast } from "react-toastify";

function TransactionSummary() {
  const permissions = usePermission(true);
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [gridApi, setGridApi] = useState<any>(null);
  const [isToast, setIsToast] = useState<any>(null);
  const tranSummaryCols = (JSON.parse(localStorage.getItem('transactionSummaryCol') || '[]'));
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    totalDisbursement: "",
    totalInvoice: "",
    totalRCP: "",
    totalSFG: "",
    csvName: "TransactionSummary.csv",
    URL: "Reports/GetOrderSummary",
    filterOptions: [],
  });

  const columnitems = [
    {
      headerName: "Customer Id",
      field: "customerId",
      headerTooltip: "Customer Id",
      tooltipShowDelay: 0,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: tranSummaryCols.includes("customerId"),
      comparator: customComparator,
    },
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      field: "orderNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: tranSummaryCols.includes("orderNumber"),
      comparator: customComparator,
    },
    {
      headerName: "Process Date",
      headerTooltip: "Process Date",
      tooltipShowDelay: 0,
      field: "processDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: tranSummaryCols.includes("processDate"),
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      comparator: customComparator,
    },
    {
      headerName: "Transaction Type",
      headerTooltip: "Transaction Type",
      tooltipField: "transactionType",
      tooltipShowDelay: 0,
      field: "transactionType",
      filter: "agTextColumnFilter",
      autoHeight: true,
      wrapText: true,
      sortable: false,
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: tranSummaryCols.includes("transactionType"),
      suppressMenu: true,
      comparator: customComparator,
      cellRenderer: function (params: any) {
        if (
          params.data.transactionType !== null &&
          params.data.transactionType !== undefined
        ) {
          const transactionTypes: any = params.data.transactionType
            .split("~")
            .filter(Boolean);
          return (
            <div>
              {transactionTypes.map((type: any, i: any) => (
                <div>
                  <div key={i}>{type.trim()}</div>
                </div>
              ))}
            </div>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      headerName: "Amount",
      headerTooltip: "Amount",
      tooltipShowDelay: 0,
      field: "amount",
      hide: tranSummaryCols.includes("amount"),
      filter: "agNumberColumnFilter",
      suppressMenu: true,
      autoHeight: true,
      wrapText: true,
      sortable: false,
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      comparator: customComparator,
      type: gridAlignment.RIGHT,
      cellRenderer: function (params: any) {
        if (params.data.amount !== null && params.data.amount !== undefined) {
          const amountTypes: any = params.data.amount
            .split("~")
            .filter(Boolean);
          return (
            <div>
              {amountTypes.map((type: any, i: any) => (
                <div>
                  <div key={i}>
                    {type.trim().endsWith(".") &&
                      !/\d/.test(type.trim().slice(4))
                      ? type.trim() + "00"
                      : type.trim().match(/\.\d$/)
                        ? type.trim() + "0"
                        : type.trim()}
                  </div>
                </div>
              ))}
            </div>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  const [colDefs, setColDefs] = useState(columnitems);

  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        transactionSummary();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      transactionSummary();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    const cols: any = [];

    columnValues.forEach((value: any) => {
      if (value?.hide === true)
        cols.push(value.field);
    });

    localStorage.setItem("transactionSummaryCol", JSON.stringify(cols));

    setIsToast(true);
    setColDefs(columnValues);
    myRef?.current.handleClose();
  };
  // this function defination change after enterprise
  const transactionSummary = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              pageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              pageSize: gridData.pageSize,
              sortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              isForExcel: gridData.isForExcel,
              globalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getTransactionSummary(data)).then((result: any) => {
              if (result?.payload?.data?.transactions?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.transactions,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData(
                "totalDisbursement",
                CurrencyConverter.format(
                  result?.payload?.data?.totalDisbursement
                )
              );
              updateGridData(
                "totalInvoice",
                CurrencyConverter.format(result?.payload?.data?.totalInvoice)
              );
              updateGridData(
                "totalRCP",
                CurrencyConverter.format(result?.payload?.data?.totalRCP)
              );
              updateGridData(
                "totalSFG",
                CurrencyConverter.format(result?.payload?.data?.totalSFG)
              );
              updateGridData("totalRows", result?.payload?.data?.totalCount);
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };

  const onExport = (e: any) => {
    if (gridData.totalRows > 100000) {
      toast.warning(
        "The system limits exports to a maximum of 100,000 records"
      );
    } else {
      updateGridData("isForExcel", true);
    }
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };

  const cardOne = {
    title: "Total Disbursement",
    count: gridData?.totalDisbursement,
    description: "",
  };

  const cardTwo = {
    title1: "Total Invoice",
    count1: gridData?.totalInvoice,
    description1: "",
  };

  const cardThree = {
    title2: "Total RCP Payment",
    count2: gridData?.totalRCP,
    description2: "",
  };

  const cardFour = {
    title3: "Total SFG",
    count3: gridData?.totalSFG,
    description3: "",
  };

  return (
    <Container fluid>
      <div className="content_header d-flex justify-content-between align-items-center flex-wrap gap-3 gap-lg-0">
        <div className="header_left flex-shrink-0">
          <h5 className="content_header_title">Transactions Summary</h5>
        </div>
        <div className="header_right">
          {permissions?.["TransactionRegisterDisbursementSummary"] && (
            <Button
              variant="primary"
              className="d-flex gap-2 align-items-center"
              onClick={(e) => {
                navigate("/reports/disbursement-summary");
              }}
            >
              <i className="fa-solid fa-hand-holding-dollar d-none d-md-inline-block"></i>
              Disbursement Summary
            </Button>
          )}
          {permissions?.["TransactionRegisterInvoiceSummary"] && (
            <Button
              variant="primary"
              className="d-flex gap-2 align-items-center"
              onClick={(e) => {
                navigate("/reports/invoice-summary");
              }}
            >
              <i className="fa-solid fa-file-lines d-none d-md-inline-block"></i>
              Invoice Summary
            </Button>
          )}
          {permissions?.["TransactionRegisterServiceFeeGuaranteesSummary"] && (
            <Button
              variant="primary"
              className="d-flex gap-2 align-items-center"
              onClick={(e) => {
                navigate("/reports/servicefeeguarantees");
              }}
            >
              <i className="fa-solid fa-award  d-none d-md-inline-block"></i>
              Service Fee Guarantee
            </Button>
          )}
        </div>
      </div>
      <div className="content_body">
        <Row>
          <Col>
            <GridTotalCountHeader
              isFourColumn={true}
              {...(permissions?.["TransactionRegisterDisbursementSummary"]
                ? cardOne
                : {})}
              {...(permissions?.["TransactionRegisterInvoiceSummary"]
                ? cardTwo
                : {})}
              {...(permissions?.["IndividualOrderSummaryRCP"] ? cardThree : {})}
              {...(permissions?.[
                "TransactionRegisterServiceFeeGuaranteesSummary"
              ]
                ? cardFour
                : {})}
            ></GridTotalCountHeader>
          </Col>
        </Row>
        <GridComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        />
      </div>
    </Container>
  );
}
export default TransactionSummary;
