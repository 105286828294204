import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import GridTotalCountHeader from "../../../../components/grid/gridTotalCountHeader";
import { useAppDispatch } from "../../../../store/hooks";
import { getPartneredSummaryDetails } from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import { useNavigate, useParams } from "react-router-dom";
import {
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useSelector } from "react-redux";

function PartneredSummary() {
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const [gridApi, setGridApi] = useState<any>(null);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const colFromStorage = localStorage.getItem('partnerSummaryCol');
  const partnerSummaryCols = (JSON.parse(colFromStorage || '[]'));
  const queryParams: any = useParams();
  const [returnableCreditSummaries, setReturnableCreditSummaries] =
    useState<any>(null);
  const [partneredSummaries, setPartneredSummaries] = useState<any>(null);
  const PartneredRow = localStorage.getItem("PartneredRow") ? JSON.parse(localStorage.getItem("PartneredRow") as any)
    : {};
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalCreditedERV: 0,
    totalOpenERV: 0,
    isForExcel: false,
    sortField: "",
    csvName: "PartneredSummary.csv",
    URL: "Reports/GetPartneredSummaryDetails",
    filterOptions: [],
    orderNumber: queryParams.id,
  });

  const columnitems = [
    {
      headerName: "Line ID",
      headerTooltip: "Line ID",
      tooltipShowDelay: 0,
      field: "lineId",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("lineId") : true,
      comparator: customComparator,
    },
    {
      headerName: "Account Hierarchy",
      headerTooltip: "Account Hierarchy",
      tooltipShowDelay: 0,
      field: "hierarchy",
      tooltipField: "hierarchy",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("hierarchy"),
      comparator: customComparator,
    },
    {
      headerName: "Customer ID",
      headerTooltip: "Customer ID",
      tooltipShowDelay: 0,
      field: "customerId",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("customerId"),
      comparator: customComparator,
    },
    {
      headerName: "Customer Name",
      headerTooltip: "Customer Name",
      tooltipShowDelay: 0,
      field: "customerName",
      tooltipField: "customerName",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("customerName"),
      comparator: customComparator,
    },
    {
      headerName: "Customer System ID",
      headerTooltip: "Customer System ID",
      tooltipShowDelay: 0,
      field: "customerSystemId",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("customerSystemId"),
      comparator: customComparator,
    },

    {
      headerName: "Industry",
      field: "industry",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("industry") : true,
      comparator: customComparator,
    },
    {
      headerName: "Service Offering",
      field: "serviceOffering",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("serviceOffering") : true,
      comparator: customComparator,
    },
    {
      headerName: "Collection Option",
      field: "collectionOption",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("collectionOption") : true,
      comparator: customComparator,
    },
    {
      headerName: "Pickup Type",
      field: "pickupType",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("pickupType") : true,
      comparator: customComparator,
    },
    {
      headerName: "Return Program",
      field: "returnProgram",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("returnProgram") : true,
      comparator: customComparator,
    },
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      field: "orderNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("orderNumber"),
      comparator: customComparator,
    },
    {
      headerName: "Pickup Date",
      field: "pickupDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
      },
      hide: colFromStorage ? partnerSummaryCols.includes("pickupDate") : true,
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Received Date",
      field: "receivedDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
      },
      hide: colFromStorage ? partnerSummaryCols.includes("receivedDate") : true,
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Process Date",
      headerTooltip: "Process Date",
      tooltipShowDelay: 0,
      field: "processDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: partnerSummaryCols.includes("processDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Manufacturer",
      headerTooltip: "Manufacturer",
      tooltipShowDelay: 0,
      field: "manufacturer",
      tooltipField: "manufacturer",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("manufacturer"),
      comparator: customComparator,
    },
    {
      headerName: "NDC",
      headerTooltip: "NDC",
      tooltipShowDelay: 0,
      field: "ndc",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("ndc"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Product",
      headerTooltip: "Product",
      tooltipShowDelay: 0,
      tooltipField: "product",
      field: "product",
      hide: partnerSummaryCols.includes("product"),
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      comparator: customComparator,
    },
    {
      headerName: "Generic Name",
      field: "genericName",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("genericName") : true,
      comparator: customComparator,
    },
    {
      headerName: "Case And Package Size",
      headerTooltip: "Case And Package Size",
      tooltipShowDelay: 0,
      field: "packagesAndSize",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("packagesAndSize"),
      comparator: customComparator,
    },
    {
      headerName: "Case Pack",
      field: "casePack",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("casePack") : true,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },

    {
      headerName: "Package Description",
      field: "packageDescription",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("packageDescription") : true,
      comparator: customComparator,
    },
    {
      headerName: "Package Size",
      field: "packageSize",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("packageSize") : true,
      comparator: customComparator,
    },
    {
      headerName: "Dosage Form",
      field: "dosageForm",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("dosageForm") : true,
      comparator: customComparator,
    },
    {
      headerName: "UOM",
      field: "uom",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("uom") : true,
      comparator: customComparator,
    },
    {
      headerName: "Strength",
      field: "strength",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("strength") : true,
      comparator: customComparator,
    },
    {
      headerName: "RX/OTC",
      field: "rxOtc",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("rxOtc") : true,
      comparator: customComparator,
    },
    {
      headerName: "DEA Class",
      headerTooltip: "DEA Class",
      tooltipShowDelay: 0,
      field: "deaClass",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("deaClass"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "EPA Code",
      field: "epaCode",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("epaCode") : true,
      comparator: customComparator,
    },
    {
      headerName: "EPA Generic Name",
      field: "epaGenericName",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("epaGenericName") : true,
      comparator: customComparator,
    },
    {
      headerName: "Unit Count",
      field: "unitCount",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("unitCount") : true,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Quantity",
      headerTooltip: "Quantity",
      tooltipShowDelay: 0,
      field: "quantity",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("quantity"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Full Quantity",
      field: "fullQuantity",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("fullQuantity") : true,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Partial Quantity",
      field: "partialQuantity",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("partialQuantity") : true,
      comparator: customComparator,
    },
    {
      headerName: "Lot#",
      headerTooltip: "Lot#",
      tooltipShowDelay: 0,
      field: "lotNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("lotNumber"),
      comparator: customComparator,
    },
    {
      headerName: "GS1 Serial#",
      field: "gs1Serial",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("gs1Serial") : true,
      comparator: customComparator,
    },
    {
      headerName: "Expiration Date",
      headerTooltip: "Expiration Date",
      tooltipShowDelay: 0,
      field: "expirationDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: partnerSummaryCols.includes("expirationDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Overstock Return Date",
      headerTooltip: "Overstock Return Date",
      tooltipShowDelay: 0,
      field: "overstockReturnDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: partnerSummaryCols.includes("overstockReturnDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Credit Disposition",
      headerTooltip: "Credit Disposition",
      tooltipShowDelay: 0,
      field: "creditDisposition",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("creditDisposition"),
      comparator: customComparator,
    },
    {
      headerName: "ERV",
      headerTooltip: "ERV",
      tooltipShowDelay: 0,
      field: "erv",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("erv"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Non-Returnable Value",
      headerTooltip: "Non-Returnable Value",
      tooltipShowDelay: 0,
      field: "nonReturnableValue",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("nonReturnableValue"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Non-Returnable Reason",
      headerTooltip: "Non-Returnable Reason",
      tooltipShowDelay: 0,
      field: "nonReturnableReason",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("nonReturnableReason"),
      comparator: customComparator,
    },
    {
      headerName: "Expected Credit Method",
      field: "expectedCreditMethod",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? partnerSummaryCols.includes("expectedCreditMethod") : true,
      comparator: customComparator,
    },
    {
      headerName: "RCP Eligible",
      headerTooltip: "RCP Eligible",
      tooltipShowDelay: 0,
      field: "rcpEligible",
      hide: partnerSummaryCols.includes("rcpEligible"),
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      comparator: customComparator,
    },
    {
      headerName: "Credit Status",
      headerTooltip: "Credit Status",
      tooltipShowDelay: 0,
      field: "creditStatus",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("creditStatus"),
      comparator: customComparator,
    },
    {
      headerName: "ARV",
      headerTooltip: "ARV",
      tooltipShowDelay: 0,
      field: "arv",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("arv"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Denial Reason",
      headerTooltip: "Denial Reason",
      tooltipShowDelay: 0,
      field: "denialReason",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: partnerSummaryCols.includes("denialReason"),
      comparator: customComparator,
    },
  ];

  const [colDefs, setColDefs] = useState(columnitems);

  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        partneredDetails();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      partneredDetails();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    const cols: any = [];

    columnValues.forEach((value: any) => {
      if (value?.hide === true)
        cols.push(value.field);
    });

    localStorage.setItem("partnerSummaryCol", JSON.stringify(cols));
    setColDefs(columnValues);
    myRef?.current.handleClose();
  };
  // this function defination change after enterprise
  const partneredDetails = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              pageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              pageSize: gridData.pageSize,
              sortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              isForExcel: gridData.isForExcel,
              globalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
              orderNumber: queryParams.id,
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getPartneredSummaryDetails(data)).then((result: any) => {
              if (result?.payload?.data?.orderLineDetails?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.orderLineDetails,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              setReturnableCreditSummaries(
                result?.payload?.data?.returnableCreditSummaries
              );
              setPartneredSummaries(result?.payload?.data?.partneredSummaries);
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? 0 : 1);
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };

  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };
  return (
    <Container fluid>
      <PageTitle
        name={"Order History - " + queryParams.id}
        buttonName="Back"
        routePage="/reports/order-history"
      ></PageTitle>

      <div className="order-history-top">
        <div className="content_body">
          <Row>
            <Col xs={12} md={6}>
              <div className="highlight-section">
                <div className="header d-flex align-items-center">
                  <label className="me-4">Return Program: </label>
                  <span className="value">{PartneredRow?.returnProgram || ""}</span>
                </div>

                <div className="first-section d-flex flex-wrap gap-3 justify-content-between my-3">
                  <div className="d-flex flex-column">
                    <label>Pickup Date: </label>
                    <span className="value">
                      {partneredSummaries?.pickupDate}
                    </span>
                  </div>

                  <div className="d-flex flex-column">
                    <label>Received Date: </label>
                    <span className="value">
                      {partneredSummaries?.receivedDate}
                    </span>
                  </div>

                  <div className="d-flex flex-column">
                    <label>Process Date: </label>
                    <span className="value">
                      {partneredSummaries?.processDate}
                    </span>
                  </div>
                </div>

                <div className="d-flex flex-wrap flex-xl-nowrap p-3 gap-4 gap-xl-0">
                  <div className="d-flex flex-column min-width-20">
                    <label>Pallet Count</label>
                    <span className="value">
                      {partneredSummaries?.totalPalletCount}
                    </span>
                  </div>

                  <div className="d-flex flex-column min-width-20">
                    <label>Box Count</label>
                    <span className="value">
                      {partneredSummaries?.totalBoxCount}
                    </span>
                  </div>

                  <div className="d-flex flex-column min-width-20">
                    <label>Regular Waste</label>
                    <span className="value">
                      {partneredSummaries?.totalRegularWaste} lbs
                    </span>
                  </div>

                  <div className="d-flex flex-column min-width-20">
                    <label>Hazardous Waste</label>
                    <span className="value">
                      {partneredSummaries?.totalHazardousWaste} lbs
                    </span>
                  </div>

                  <div className="d-flex flex-column min-width-20">
                    <label>Controlled Waste</label>
                    <span className="value">
                      {partneredSummaries?.totalControlledWaste} lbs
                    </span>
                  </div>
                </div>

                <div className="d-flex flex-wrap flex-xl-nowrap p-3 gap-4 gap-xl-0">
                  <div className="d-flex flex-column min-width-20">
                    <label>Returnable ERV</label>
                    <span className="value">
                      {partneredSummaries?.totalReturnableERV}
                    </span>
                  </div>

                  <div className="d-flex flex-column min-width-20">
                    <label>Overstock ERV </label>
                    <span className="value">
                      {partneredSummaries?.totalOverstockERV}
                    </span>
                  </div>

                  <div className="d-flex flex-column min-width-20">
                    <label>Total ERV </label>
                    <span className="value">
                      {partneredSummaries?.totalERV}
                    </span>
                  </div>

                  <div className="d-flex flex-column min-width-20">
                    <label>Invoice </label>
                    <span className="value">
                      {partneredSummaries?.totalInvoice}
                    </span>
                  </div>

                  <div></div>
                </div>
              </div>
            </Col>

            <Col xs={12} md={6}>
              <div className="detail-table mt-3 mt-md-0">
                <Table responsive>
                  <thead className="sticky-top">
                    <tr>
                      <th>Returnable Credit Summary</th>
                      <th>ERV</th>
                      <th>ARV</th>
                      <th>Outstanding ERV</th>
                    </tr>
                  </thead>
                  <tbody>
                    {returnableCreditSummaries &&
                      returnableCreditSummaries.length > 0 &&
                      returnableCreditSummaries.map((r: any) => (
                        <tr>
                          <td>{r.creditStatus}</td>
                          <td>{r.erv}</td>
                          <td>{r.creditAmount}</td>
                          <td>{r.outstandingERV}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="content_body">
        <GridComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
          autoSizeStrategy={{
            type: "fitCellContents",
            colIds: [
              "orderNumber",
              "pickupDate",
              "processDate",
              "boxCount",
              "palletCount",
              "regularWaste",
              "hazardousWaste",
              "controlledWaste",
              "returnableERV",
              "overstockERV",
              "totalERV",
              "invoice",
              "unrecoveredCredits",
              "disqualifiedCredits",
              "serviceFeeRate",
              "serviceFeeRefund",
              "netInvoice",
            ],
          }}
        />
      </div>
    </Container>
  );
}
export default PartneredSummary;
