import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useAppDispatch } from "../../../../store/hooks";
import { getDestructionFormList } from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import {
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import downloadFileFromUrl from "../../../shared/csvDownload/fileDownloader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getTableFields } from "../../../../helpers/getTableFields";

function DestructionForm() {
  const gridRef = useRef<AgGridReact>(null);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const [gridApi, setGridApi] = useState<any>(null);
  const destructionFormCols = (JSON.parse(localStorage.getItem('destructionFormCol') || '[]'));
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "Form41.csv",
    URL: "Reports/Form41",
    filterOptions: [],
  });

  const columnItems = [
    {
      headerName: "Customer ID",
      field: "customerId",
      headerTooltip: "Customer ID",
      tooltipShowDelay: 0,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: destructionFormCols.includes("customerId"),
      comparator: customComparator,
    },
    {
      headerName: "Order#",
      field: "orderNumber",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: destructionFormCols.includes("orderNumber"),
      comparator: customComparator,
    },
    {
      headerName: "Manifest#",
      headerTooltip: "Manifest#",
      tooltipShowDelay: 0,
      field: "manifestNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: destructionFormCols.includes("manifestNumber"),
      comparator: customComparator,
      cellStyle: { cursor: "pointer" },
      cellRenderer: function (params: any) {
        return (
          <div
            className="text-link"
            onClick={(e) => {
              downloadFileFromUrl(
                `Reports/DownloadForm41?orderNumber=${params.data.orderNumber}&manifestNumber=${params.data.manifestNumber}`,
                `Form41_${params.data.orderNumber}.pdf`
              );
            }}
          >
            {params.data.manifestNumber}
          </div>
        );
      },
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Destruction Date",
      headerTooltip: "Destruction Date",
      tooltipShowDelay: 0,
      field: "destructionDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: destructionFormCols.includes("destructionDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Witness 1",
      headerTooltip: "Witness 1",
      tooltipShowDelay: 0,
      field: "witness1",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: destructionFormCols.includes("witness1"),
      comparator: customComparator,
    },
    {
      headerName: "Witness 2",
      headerTooltip: "Witness 2",
      tooltipShowDelay: 0,
      field: "witness2",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: destructionFormCols.includes("witness2"),
      comparator: customComparator,
    },
    {
      headerName: "Status",
      headerTooltip: "Status",
      tooltipShowDelay: 0,
      field: "status",
      hide: destructionFormCols.includes("status"),
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      cellRenderer: function (params: any) {
        let tmpClass: any = "";
        if (
          params.data.status === "Received" ||
          params.data.status === "Processed"
        ) {
          tmpClass = "created";
        } else if (
          params.data.status === "Void" ||
          params.data.status === "Destroyed"
        ) {
          tmpClass = "destroyed";
        } else if (params.data.status === "Pending Receipt") {
          tmpClass = "pending";
        } else {
          tmpClass = "default-status";
        }
        return (
          <div className="status">
            <div className={tmpClass}>{params.data.status}</div>
          </div>
        );
      },
    },
  ];

  const [colDefs, setColDefs] = useState(columnItems);

  function customComparator() {
    return 0; //means no comparing and no sorting
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    const cols: any = [];

    columnValues.forEach((value: any) => {
      if (value?.hide === true)
        cols.push(value.field);
    });

    localStorage.setItem("destructionFormCol", JSON.stringify(cols));
    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();
    if (gridRef.current) {
      gridRef.current.api.autoSizeAllColumns();
    }
  };

  const Form41 = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              PageSize: gridData.pageSize,
              SortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              IsForExcel: gridData.isForExcel,
              GlobalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getDestructionFormList(data)).then((result: any) => {
              if (result?.payload?.data?.form41s?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.form41s,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData(
                "totalAmount",
                CurrencyConverter.format(result?.payload?.data?.totalAmount)
              );
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        Form41();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      Form41();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs),formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange =() => {
    if(gridApi) {
        updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  }
  return (
    <Container fluid>
      <PageTitle
        name="Form 41 Report"
        buttonName="Back"
        routePage="/reports/view-all"
      ></PageTitle>
      <div className="content_body grid-reset">
        <GridComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          shortOrder={gridData.sortOrder}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </div>
    </Container>
  );
}

export default DestructionForm;
