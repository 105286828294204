import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useAppDispatch } from "../../../../store/hooks";
import { getEPAComplianceNotification } from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import {
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getTableFields } from "../../../../helpers/getTableFields";

function EPACompliance() {
  const gridRef = useRef<AgGridReact>(null);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const [gridApi, setGridApi] = useState<any>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const epaComplianceCols = (JSON.parse(localStorage.getItem('epaComplianceCol') || '[]'));
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "EPAComplianceNotification.csv",
    URL: "Reports/EPAComplianceNotification",
    filterOptions: [],
  });
  const columnItems = [
    {
      headerName: "Customer Name",
      field: "customerName",
      headerTooltip: "Customer Name",
      tooltipShowDelay: 0,
      hide: epaComplianceCols.includes("customerName"),
      comparator: customComparator,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      field: "orderNumber",
      hide: epaComplianceCols.includes("orderNumber"),
      comparator: customComparator,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Tracking#",
      headerTooltip: "Tracking#",
      tooltipShowDelay: 0,
      field: "trackingNumber",
      hide: epaComplianceCols.includes("trackingNumber"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Received On",
      headerTooltip: "Received On",
      tooltipShowDelay: 0,
      field: "receivedOn",
      hide: epaComplianceCols.includes("receivedOn"),
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      comparator: customComparator,
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
    },
  ];
  const localdata: any = localStorage.getItem("EPAComplianceNotificationCol");
  const [colDefs, setColDefs] = useState(columnItems);
  function customComparator() {
    return 0; //means no comparing and no sorting
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    // setTimeout(() => {
    //   params.api.autoSizeAllColumns();
    // }, 100);
  };
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    const cols: any = [];

    columnValues.forEach((value: any) => {
      if (value?.hide === true)
        cols.push(value.field);
    });

    localStorage.setItem("epaComplianceCol", JSON.stringify(cols));
    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();
    if (gridRef.current) {
      gridRef.current.api.autoSizeAllColumns();
    }
  };

  const EPAComplianceNotification = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageSize: gridData.pageSize,
              PageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              GlobalSearch: gridData.globalSearch,
              IsForExcel: gridData.isForExcel,
              SortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getEPAComplianceNotification(data)).then((result: any) => {
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData(
                "totalAmount",
                CurrencyConverter.format(result?.payload?.data?.totalAmount)
              );
              updateGridData(
                "rows",
                result?.payload?.data?.epaComplianceNotification
              );

              if (
                result?.payload?.data?.epaComplianceNotification?.length === 0
              ) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.epaComplianceNotification,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        EPAComplianceNotification();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      EPAComplianceNotification();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const onExport = (e: any) => {
    if (gridData.totalRows > 100000) {
      toast.warning(
        "The system limits exports to a maximum of 100,000 records"
      );
    } else {
      updateGridData("isForExcel", true);
    }
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };
  return (
    <Container fluid>
      <PageTitle
        name="EPA Compliance Notification"
        buttonName="Back"
        routePage="/reports/view-all"
      ></PageTitle>
      <div className="content_body grid-reset">
        <div className="report-content ">
          <p>
            Below are electronic confirmations that the shipment(s) listed below
            have arrived at Pharma Logistics that contained:
          </p>
          <ul>
            <li>
              Potentially Creditable Hazardous Waste Pharmaceuticals (PCHWP),
              and/or{" "}
            </li>
            <li>Potentially Creditable Non-Hazardous Waste Pharmaceuticals</li>
          </ul>
          <p>
            Per 40 CFR §.509, 266, Pharma Logistics is required to provide
            electronic delivery confirmation notice for each shipment of
            potentially creditable hazardous waste pharmaceuticals indicating
            that the shipment is under our custody and control.
          </p>
          <p>
            Once the Sales Order has been processed, items that have been
            received will be available on the customer portal.
          </p>
          <p>
            Per 40 CFR 266.503(e), you are required to maintain this delivery
            confirmation for a period of 3 years.
          </p>
        </div>

        <GridComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          shortOrder={gridData.sortOrder}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </div>
    </Container>
  );
}
export default EPACompliance;
