import { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { getInventoryReports } from "../../../../store/api/apiSlice";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useAppDispatch } from "../../../../store/hooks";

import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import getFiles from "../../../shared/csvDownload/csvDownload";

function InventoryReports() {
  const gridRef = useRef<AgGridReact>(null);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const myRef = useRef<any>();

  const dispatch = useAppDispatch();
  const inventoryReportCols = (JSON.parse(localStorage.getItem('inventoryReportCol') || '[]'));
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "InventoryReports.csv",
    URL: "Reports/GetInventories",
    filterOptions: [],
  });

  const columnItems = [
    {
      headerName: "Customer ID",
      headerTooltip: "Customer ID",
      tooltipShowDelay: 0,
      field: "customerId",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: inventoryReportCols.includes("customerId"),
      comparator: customComparator,
    },
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      field: "orderNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: inventoryReportCols.includes("orderNumber"),
      comparator: customComparator,
    },
    {
      headerName: "Collection Option",
      headerTooltip: "Collection Option",
      field: "collectionOption",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: inventoryReportCols.includes("collectionOption"),
      comparator: customComparator,
    },
    {
      headerName: "NDC",
      headerTooltip: "NDC",
      tooltipShowDelay: 0,
      field: "ndc",
      tooltipField: "ndc",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: inventoryReportCols.includes("ndc"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Full Quantity",
      headerTooltip: "Full Quantity",
      field: "fullQuantity",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: inventoryReportCols.includes("fullQuantity"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Partial Quantity",
      headerTooltip: "Partial Quantity",
      field: "partialQuantity",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: inventoryReportCols.includes("partialQuantity"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Product",
      headerTooltip: "Product",
      tooltipShowDelay: 0,
      tooltipField: "product",
      field: "product",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: inventoryReportCols.includes("product"),
      comparator: customComparator,
    },
    {
      headerName: "Case And Package Size",
      headerTooltip: "Case And Package Size",
      field: "caseAndPackageSize",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: inventoryReportCols.includes("caseAndPackageSize"),
      comparator: customComparator,
    },
    {
      headerName: "Quantity Validation",
      headerTooltip: "Quantity Validation",
      tooltipShowDelay: 0,
      tooltipField: "quantityValidation",
      field: "quantityValidation",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: inventoryReportCols.includes("quantityValidation"),
      comparator: customComparator,
    },
    {
      headerName: "DEA Class",
      headerTooltip: "DEA Class",
      tooltipShowDelay: 0,
      field: "deaClass",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: inventoryReportCols.includes("deaClass"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Lot#",
      headerTooltip: "Lot#",
      tooltipShowDelay: 0,
      field: "lot",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: inventoryReportCols.includes("lot"),
      comparator: customComparator,
    },
    {
      headerName: "Expiration Date",
      headerTooltip: "Expiration Date",
      field: "expirationDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: inventoryReportCols.includes("expirationDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "GS1 Serial#",
      headerTooltip: "GS1 Serial#",
      tooltipShowDelay: 0,
      field: "gs1Serial",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: inventoryReportCols.includes("gs1Serial"),
      comparator: customComparator,
    },
    {
      headerName: "Inventory Date",
      headerTooltip: "Inventory Date",
      field: "inventoryDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: inventoryReportCols.includes("inventoryDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Inventoried By",
      headerTooltip: "Inventoried By",
      tooltipShowDelay: 0,
      field: "inventoriedBy",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: inventoryReportCols.includes("inventoriedBy"),
      comparator: customComparator,
    },
  ];

  const [colDefs, setColDefs] = useState(columnItems);

  function customComparator() {
    return 0; //means no comparing and no sorting
  }

  const onGridReady = (params: any) => {
    setTimeout(() => {
      params.api.autoSizeAllColumns();
    }, 100);

    setGridApi(params.api);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    const cols: any = [];

    columnValues.forEach((value: any) => {
      if (value?.hide === true)
        cols.push(value.field);
    });

    localStorage.setItem("inventoryReportCol", JSON.stringify(cols));
    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();

    if (gridRef.current) {
      gridRef.current.api.autoSizeAllColumns();
    }
  };

  const inventoryReports = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              PageSize: gridData.pageSize,
              SortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              IsForExcel: gridData.isForExcel,
              GlobalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getInventoryReports(data)).then((result: any) => {
              if (result?.payload?.data?.inventory?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.inventory,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData(
                "totalAmount",
                CurrencyConverter.format(result?.payload?.data?.totalAmount)
              );
            });
          },
        };

        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        inventoryReports();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      inventoryReports();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs),formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };

  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange =() => {
    if(gridApi) {
        updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  }
  return (
    <Container fluid>
      <PageTitle
        name="Inventory Reports"
        buttonName="Back"
        routePage="/reports/view-all"
      ></PageTitle>
      <div className="content_body grid-reset">
        <GridComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          autoSizeStrategy={{
            type: "fitCellContents",
            colIds: ["orderNumber", "lotNumber", "deaClass"],
          }}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          shortOrder={gridData.sortOrder}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </div>
    </Container>
  );
}

export default InventoryReports;
