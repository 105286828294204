import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useAppDispatch } from "../../../../store/hooks";
import { getOrderLineDetails } from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import BarChart from "../../../../components/charts/barChart";
import {
  defaultNoRecordFound,
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { toast } from "react-toastify";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getTableFields } from "../../../../helpers/getTableFields";
import { usePermission } from "../../../../helpers/hooks/usePermissions";

function OrderLineDetails() {
  const permissions = usePermission(true);
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const colFromStorage = localStorage.getItem('orderLineCol');
  const orderLineCols = (JSON.parse(colFromStorage || '[]'));
  const [gridApi, setGridApi] = useState<any>(null);
  const [manufacturerTotal, setManufacturerTotal] = useState(0);
  const [topManufacturer, setTopManufacturer] = useState([]);
  const [topProduct, setTopProducts] = useState([]);
  const [productTotal, setProductTotal] = useState(0);
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "OrderLineDetail.csv",
    URL: "Reports/OrderLineDetail",
    filterOptions: [],
  });
  const selectOptionRef = useRef("1");
  const selectProductOptionRef = useRef("2");
  const [selectedOption, setSelectedOption] = useState({ value: "1", label: "Returnable" });
  const [selectedProductOption, setSelectedProductOption] = useState({ value: "2", label: "Non-Returnable" });
  const [dataTopManufacturers, setDataTopManufacturers] = useState({
    labels: [],
    datasets: [
      {
        label: "ERV",
        backgroundColor: "rgba(0,3,81,0.8)",
        borderColor: "rgba(0,3,81,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(0,3,81,0.4)",
        hoverBorderColor: "rgba(0,3,81,1)",
        data: [],
        borderRadius: 15,
        barThickness: 15,
      },
    ],
  });
  const optionsTopManufacturers = {
    scales: {
      x: {
        ticks: {
          callback: (value: any, index: any, values: any) => {
            let label: any = dataTopManufacturers.labels[value];
            if (label == null) {
              return "";
            }
            if (label.length > 10) {
              label = label.substring(0, 10);
              label += "..";
            }
            return label;
          },
          maxRotation: 90,
          minRotation: 45,
        },
        grid: { display: false },
      },
      y: {
        title: {
          display: true,
          text: selectedOption?.value === "1" ? "ERV" : "Non-Returnable",
        },
        type: "linear",
        position: "left",
      },
    },
    plugins: {
      title: {
        display: false,
        text: "TOP MANUFACTURERS",
        position: "top",
      },
      legend: {
        display: false,
        position: "bottom",
        rotation: 1,
        onClick: null,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
        },
      },
    },
  };

  const [dataTopProducts, setDataTopProducts] = useState({
    labels: [],
    datasets: [
      {
        label: "ERV",
        backgroundColor: "rgba(179,0,0,0.8)",
        borderColor: "rgba(179,0,0,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(179,0,0,0.4)",
        hoverBorderColor: "rgba(179,0,0,1)",
        data: [],
        borderRadius: 15,
        barThickness: 15,
      },
    ],
  });
  const optionsTopProducts = {
    scales: {
      x: {
        ticks: {
          callback: (value: any, index: any, values: any) => {
            let label: any = dataTopProducts.labels[value];
            if (label == null) {
              return "";
            }
            if (label.length > 10) {
              label = label.substring(0, 10);
              label += "..";
            }
            return label;
          },
          maxRotation: 90,
          minRotation: 45,
        },
        grid: { display: false },
      },
      y: {
        title: {
          display: true,
          text: selectedProductOption?.value === "1" ? "ERV" : "Non-Returnable",
        },
        type: "linear",
        position: "left",
      },
    },
    plugins: {
      title: {
        display: false,
        text: "TOP Products",
        position: "top",
      },
      legend: {
        display: false,
        position: "bottom",
        rotation: 1,
        onClick: null,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
        },
      },
    },
  };

  const columnItems = [
    // {
    //   headerName: "Data Source Line Unique Key",
    //   field: "dataSourceLineUniqueKey",
    //   filter: "agNumberColumnFilter",
    //   filterParams: {
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //   },
    //   menuTabs: ["filterMenuTab"],
    //   hide: true,
    //   comparator: customComparator,
    //   type: gridAlignment.RIGHT,
    // },
    {
      headerName: "Line ID",
      headerTooltip: "Line ID",
      tooltipShowDelay: 0,
      field: "lineId",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("lineId") : true,
      comparator: customComparator,
    },
    // {
    //   headerName: "Group ID",
    //   field: "groupId",
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //     suppressAndOrCondition: true,
    //   },
    //   menuTabs: ["filterMenuTab"],
    //   hide: true,
    //   comparator: customComparator,
    // },
    // {
    //   headerName: "Group Name",
    //   field: "groupName",
    //   tooltipField: "groupName",
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //     suppressAndOrCondition: true,
    //   },
    //   menuTabs: ["filterMenuTab"],
    //   hide: true,
    //   comparator: customComparator,
    // },
    {
      headerName: "Account Hierarchy",
      headerTooltip: "Account Hierarchy",
      tooltipShowDelay: 0,
      field: "hierarchy",
      tooltipField: "hierarchy",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("hierarchy"),
      comparator: customComparator,
    },
    {
      headerName: "Customer ID",
      headerTooltip: "Customer ID",
      tooltipShowDelay: 0,
      field: "customerId",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("customerId"),
      comparator: customComparator,
    },
    {
      headerName: "Customer Name",
      headerTooltip: "Customer Name",
      tooltipShowDelay: 0,
      field: "customerName",
      tooltipField: "customerName",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("customerName"),
      comparator: customComparator,
    },
    {
      headerName: "Customer System ID",
      headerTooltip: "Customer System ID",
      tooltipShowDelay: 0,
      field: "customerSystemId",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("customerSystemId"),
      comparator: customComparator,
    },

    {
      headerName: "Industry",
      field: "industry",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("industry") : true,
      comparator: customComparator,
    },
    {
      headerName: "Service Offering",
      field: "serviceOffering",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("serviceOffering") : true,
      comparator: customComparator,
    },
    {
      headerName: "Collection Option",
      field: "collectionOption",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("collectionOption") : true,
      comparator: customComparator,
    },
    {
      headerName: "Pickup Type",
      field: "pickupType",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("pickupType") : true,
      comparator: customComparator,
    },
    {
      headerName: "Return Program",
      field: "returnProgram",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("returnProgram") : true,
      comparator: customComparator,
    },
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      field: "orderNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("orderNumber"),
      comparator: customComparator,
    },
    {
      headerName: "Pickup Date",
      field: "pickupDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
      },
      hide: colFromStorage ? orderLineCols.includes("pickupDate") : true,
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Received Date",
      field: "receivedDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
      },
      hide: colFromStorage ? orderLineCols.includes("receivedDate") : true,
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Process Date",
      headerTooltip: "Process Date",
      tooltipShowDelay: 0,
      field: "processDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: orderLineCols.includes("processDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Manufacturer",
      headerTooltip: "Manufacturer",
      tooltipShowDelay: 0,
      field: "manufacturer",
      tooltipField: "manufacturer",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("manufacturer"),
      comparator: customComparator,
    },
    {
      headerName: "NDC",
      headerTooltip: "NDC",
      tooltipShowDelay: 0,
      field: "ndc",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("ndc"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Product",
      headerTooltip: "Product",
      tooltipShowDelay: 0,
      tooltipField: "product",
      field: "product",
      hide: orderLineCols.includes("product"),
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      comparator: customComparator,
    },
    {
      headerName: "Generic Name",
      field: "genericName",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("genericName") : true,
      comparator: customComparator,
    },
    {
      headerName: "Case And Package Size",
      headerTooltip: "Case And Package Size",
      tooltipShowDelay: 0,
      field: "packagesAndSize",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("packagesAndSize"),
      comparator: customComparator,
    },
    {
      headerName: "Case Pack",
      field: "casePack",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("casePack") : true,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },

    {
      headerName: "Package Description",
      field: "packageDescription",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("packageDescription") : true,
      comparator: customComparator,
    },
    {
      headerName: "Package Size",
      field: "packageSize",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("packageSize") : true,
      comparator: customComparator,
    },
    {
      headerName: "Dosage Form",
      field: "dosageForm",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("dosageForm") : true,
      comparator: customComparator,
    },
    {
      headerName: "UOM",
      field: "uom",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("uom") : true,
      comparator: customComparator,
    },
    {
      headerName: "Strength",
      field: "strength",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("strength") : true,
      comparator: customComparator,
    },
    {
      headerName: "RX/OTC",
      field: "rxOtc",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("rxOtc") : true,
      comparator: customComparator,
    },
    {
      headerName: "DEA Class",
      headerTooltip: "DEA Class",
      tooltipShowDelay: 0,
      field: "deaClass",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("deaClass"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    // {
    //   headerName: "222 Form Serial Number",
    //   field: "formSerialNumber",
    //   filter: "agNumberColumnFilter",
    //   filterParams: {
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //   },
    //   menuTabs: ["filterMenuTab"],
    //   hide: orderLineCols.includes("orderNumber"),
    //   comparator: customComparator,
    //   type: gridAlignment.RIGHT,
    // },
    // {
    //   headerName: "222 Form Line Number",
    //   field: "formLineNumber",
    //   filter: "agNumberColumnFilter",
    //   filterParams: {
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //   },
    //   menuTabs: ["filterMenuTab"],
    //   hide: orderLineCols.includes("orderNumber"),
    //   comparator: customComparator,
    //   type: gridAlignment.RIGHT,
    // },
    {
      headerName: "EPA Code",
      field: "epaCode",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("epaCode") : true,
      comparator: customComparator,
    },
    {
      headerName: "EPA Generic Name",
      field: "epaGenericName",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("epaGenericName") : true,
      comparator: customComparator,
    },
    {
      headerName: "Unit Count",
      field: "unitCount",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("unitCount") : true,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Quantity",
      headerTooltip: "Quantity",
      tooltipShowDelay: 0,
      field: "quantity",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("quantity"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Full Quantity",
      field: "fullQuantity",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("fullQuantity") : true,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Partial Quantity",
      field: "partialQuantity",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("partialQuantity") : true,
      comparator: customComparator,
    },
    {
      headerName: "Lot#",
      headerTooltip: "Lot#",
      tooltipShowDelay: 0,
      field: "lotNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("lotNumber"),
      comparator: customComparator,
    },
    {
      headerName: "GS1 Serial#",
      field: "gs1Serial",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("gs1Serial") : true,
      comparator: customComparator,
    },
    {
      headerName: "Expiration Date",
      headerTooltip: "Expiration Date",
      tooltipShowDelay: 0,
      field: "expirationDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: orderLineCols.includes("expirationDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Overstock Return Date",
      headerTooltip: "Overstock Return Date",
      tooltipShowDelay: 0,
      field: "overstockReturnDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: orderLineCols.includes("overstockReturnDate"),
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Credit Disposition",
      headerTooltip: "Credit Disposition",
      tooltipShowDelay: 0,
      field: "creditDisposition",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("creditDisposition"),
      comparator: customComparator,
    },
    {
      headerName: "ERV",
      headerTooltip: "ERV",
      tooltipShowDelay: 0,
      field: "erv",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("erv"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Non-Returnable Value",
      headerTooltip: "Non-Returnable Value",
      tooltipShowDelay: 0,
      field: "nonReturnableValue",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("nonReturnableValue"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Non-Returnable Reason",
      headerTooltip: "Non-Returnable Reason",
      tooltipShowDelay: 0,
      field: "nonReturnableReason",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("nonReturnableReason"),
      comparator: customComparator,
    },
    // {
    //   headerName: "Non-Returnable Category",
    //   field: "nonReturnableCategory",
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //     suppressAndOrCondition: true,
    //   },
    //   menuTabs: ["filterMenuTab"],
    //   hide: true,
    //   comparator: customComparator,
    // },
    // {
    //   headerName: "Non Returnable Agent",
    //   field: "nonReturnableAgent",
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //     suppressAndOrCondition: true,
    //   },
    //   menuTabs: ["filterMenuTab"],
    //   hide: true,
    //   comparator: customComparator,
    // },
    // {
    //   headerName: "Non-Returnable Opportunity",
    //   field: "nonReturnableOpportunity",
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //     suppressAndOrCondition: true,
    //   },
    //   menuTabs: ["filterMenuTab"],
    //   hide: true,
    //   comparator: customComparator,
    // },
    {
      headerName: "Expected Credit Method",
      field: "expectedCreditMethod",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: colFromStorage ? orderLineCols.includes("expectedCreditMethod") : true,
      comparator: customComparator,
    },
    {
      headerName: "RCP Eligible",
      headerTooltip: "RCP Eligible",
      tooltipShowDelay: 0,
      field: "rcpEligible",
      hide: orderLineCols.includes("rcpEligible"),
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      comparator: customComparator,
    },
    {
      headerName: "Credit Status",
      headerTooltip: "Credit Status",
      tooltipShowDelay: 0,
      field: "creditStatus",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("creditStatus"),
      comparator: customComparator,
    },
    {
      headerName: "ARV",
      headerTooltip: "ARV",
      tooltipShowDelay: 0,
      field: "arv",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("arv"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Denial Reason",
      headerTooltip: "Denial Reason",
      tooltipShowDelay: 0,
      field: "denialReason",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: orderLineCols.includes("denialReason"),
      comparator: customComparator,
    },
    // {
    //   headerName: "Recon Locked",
    //   field: "reconLocked",
    //   hide: true,
    //   comparator: customComparator,
    // },
  ];

  const item = [
    {
      id: "1",
      value: "Returnable",
    },
    {
      id: "2",
      value: "Non-Returnable",
    },
  ];
  const options: any = [];

  item &&
    item.map((item: any) =>
      options.push({
        value: item.id,
        label: item.value,
      })
    );

  const [colDefs, setColDefs] = useState(columnItems);

  function customComparator() {
    return 0; //means no comparing and no sorting
  }

  const onGridReady = (params: any) => {
    setTimeout(() => {
      // params.api.autoSizeAllColumns();
    }, 100);

    setGridApi(params.api);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const updateManufactureLabelData = (k: any, v: any) =>
    setDataTopManufacturers((prev) => ({ ...prev, [k]: v }));

  const updateChartData = (newData: any, param: any) => {
    if (param === "2") {
      setDataTopManufacturers({
        ...dataTopManufacturers,
        datasets: dataTopManufacturers.datasets.map((dataset) => ({
          ...dataTopManufacturers.datasets[0],
          data: newData,
          label: "Non-Returnable Value", // Reset to original label
        })),
      });
    } else {
      setDataTopManufacturers({
        ...dataTopManufacturers,
        datasets: dataTopManufacturers.datasets.map((dataset) => ({
          ...dataTopManufacturers.datasets[0],
          data: newData,
          label: "ERV", // Reset to original label
        })),
      });
    }
  };

  const updateProductLabelData = (k: any, v: any) =>
    setDataTopProducts((prev) => ({ ...prev, [k]: v }));

  const updateProductChartData = (newData: any, param: any) => {
    if (param === "1") {
      setDataTopProducts({
        ...dataTopProducts,
        datasets: dataTopProducts.datasets.map((dataset) => ({
          ...dataTopProducts.datasets[0],
          data: newData,
          label: "ERV", // Reset to original label
        })),
      });
    } else {
      setDataTopProducts({
        ...dataTopProducts,
        datasets: dataTopProducts.datasets.map((dataset) => ({
          ...dataTopProducts.datasets[0],
          data: newData,
          label: "Non-Returnable Value", // Reset to original label
        })),
      });
    }
  };

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    const cols: any = [];

    columnValues.forEach((value: any) => {
      if (value?.hide === true)
        cols.push(value.field);
    });

    localStorage.setItem("orderLineCol", JSON.stringify(cols));
    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();
    if (gridRef.current) {
      gridRef.current.api.autoSizeAllColumns();
    }
  };

  const OrderLineDetails = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              PageSize: gridData.pageSize,
              SortField: gridData.sortField,
              SortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              IsForExcel: gridData.isForExcel,
              GlobalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getOrderLineDetails(data)).then((result: any) => {
              if (result?.payload?.data?.orderLines?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.orderLines,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData(
                "totalAmount",
                CurrencyConverter.format(result?.payload?.data?.totalAmount)
              );

              // manufacture
              let label: any = [];
              let chartData: any = [];
              if (
                result &&
                result.payload &&
                result.payload.data &&
                result.payload.isSuccess &&
                result?.payload?.data?.topManufacturer?.length > 0
              ) {
                setTopManufacturer(result?.payload?.data?.topManufacturer);
                result.payload.data.topManufacturer.forEach((element: any) => {
                  if (selectOptionRef?.current === "1") {
                    if (element.type === "Returnable") {
                      label.push(element.name);
                      chartData.push(element.value);
                    }
                  } else if (selectOptionRef?.current === "2") {
                    if (element.type === "Non-Returnable") {
                      label.push(element.name);
                      chartData.push(element.value);
                    }
                  }
                });
              }
              setManufacturerTotal(chartData?.length);
              updateChartData(chartData, selectOptionRef?.current);
              updateManufactureLabelData("labels", label);

              //  top product

              let product_label: any = [];
              let product_chartData: any = [];

              if (
                result &&
                result.payload &&
                result.payload.data &&
                result.payload.isSuccess &&
                result?.payload?.data?.topProducts?.length > 0
              ) {
                setTopProducts(result?.payload?.data?.topProducts);
                result.payload.data.topProducts.forEach((element: any) => {
                  if (selectProductOptionRef?.current === "1") {
                    if (element.type === "Returnable") {
                      product_label.push(element.name);
                      product_chartData.push(element.value);
                    }
                  } else if (selectProductOptionRef?.current === "2") {
                    if (element.type === "Non-Returnable") {
                      product_label.push(element.name);
                      product_chartData.push(element.value);
                    }
                  }
                });
              }
              updateProductChartData(product_chartData, selectProductOptionRef?.current);
              updateProductLabelData("labels", product_label);
              setProductTotal(product_chartData.length);
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        OrderLineDetails();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      OrderLineDetails();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };
  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const onExport = (e: any) => {
    if (gridData.totalRows > 100000) {
      toast.warning(
        "The system limits exports to a maximum of 100,000 records"
      );
    } else {
      updateGridData("isForExcel", true);
    }
  };

  const handleSelectChange = (event: any) => {
    setSelectedOption({ ...event });
    selectOptionRef.current = event?.value;

    let label: any = [];
    let chartData: any = [];
    topManufacturer &&
      topManufacturer.forEach((element: any) => {
        if (event.value === "1") {
          if (element.type === "Returnable") {
            label.push(element.name);
            chartData.push(element.value);
          }
        } else if (event.value === "2") {
          if (element.type === "Non-Returnable") {
            label.push(element.name);
            chartData.push(element.value);
          }
        }
      });
    setManufacturerTotal(chartData?.length);
    updateChartData(chartData, event?.value);
    updateManufactureLabelData("labels", label);
  };
  const handleSelectProductChange = (event: any) => {
    setSelectedProductOption({ ...event });
    selectProductOptionRef.current = event?.value;

    let product_label: any = [];
    let product_chartData: any = [];

    topProduct &&
      topProduct.forEach((element: any) => {
        if (event.value === "1") {
          if (element.type === "Returnable") {
            product_label.push(element.name);
            product_chartData.push(element.value);
          }
        } else if (event.value === "2") {
          if (element.type === "Non-Returnable") {
            product_label.push(element.name);
            product_chartData.push(element.value);
          }
        }
      });
    updateProductChartData(product_chartData, event.value);
    updateProductLabelData("labels", product_label);
    setProductTotal(product_chartData.length);
  };
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 150,
    };
  }, []);
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };

  return (
    <Container fluid>
      <PageTitle
        name="Order Line Details"
        buttonName="Back"
        routePage="/reports/view-all"
      ></PageTitle>

      <div className="graph-wrapper">
        <Row>
          {permissions?.["OrderLineDetailTopMfgs"] && (
            <Col xs={12} md={6}>
              <div className="content_body">
                <div className="heading">
                  <h2>TOP MANUFACTURERS</h2>
                  <div className="form_box">
                    {options && (
                      <Select
                        required
                        id="dropdown-manufacturer"
                        value={selectedOption}
                        defaultValue={{ value: "1", label: "Returnable" }}
                        options={options}
                        onChange={(e) => handleSelectChange(e)}
                      />
                    )}
                  </div>
                </div>
                {manufacturerTotal > 0 && (
                  <BarChart
                    id={"top-manufacturers"}
                    data={dataTopManufacturers}
                    options={optionsTopManufacturers}
                  />
                )}
                {manufacturerTotal === 0 && (
                  <div className="no-records-wrapper mincardheight justify-content-center">
                    <svg
                      width="48"
                      height="42"
                      viewBox="0 0 48 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 5.25C0 2.3543 2.39167 0 5.33333 0H18.6667V10.5C18.6667 11.952 19.8583 13.125 21.3333 13.125H32V16.2914C25.8417 18.0059 21.3333 23.5758 21.3333 30.1875C21.3333 35.0355 23.7583 39.3176 27.475 41.9426C27.2083 41.9836 26.9417 42 26.6667 42H5.33333C2.39167 42 0 39.6457 0 36.75V5.25ZM32 10.5H21.3333V0L32 10.5ZM36 18.375C39.1826 18.375 42.2348 19.6195 44.4853 21.8348C46.7357 24.0501 48 27.0546 48 30.1875C48 33.3204 46.7357 36.3249 44.4853 38.5402C42.2348 40.7555 39.1826 42 36 42C32.8174 42 29.7652 40.7555 27.5147 38.5402C25.2643 36.3249 24 33.3204 24 30.1875C24 27.0546 25.2643 24.0501 27.5147 21.8348C29.7652 19.6195 32.8174 18.375 36 18.375ZM36 38.0625C36.5304 38.0625 37.0391 37.8551 37.4142 37.4859C37.7893 37.1167 38 36.6159 38 36.0938C38 35.5716 37.7893 35.0708 37.4142 34.7016C37.0391 34.3324 36.5304 34.125 36 34.125C35.4696 34.125 34.9609 34.3324 34.5858 34.7016C34.2107 35.0708 34 35.5716 34 36.0938C34 36.6159 34.2107 37.1167 34.5858 37.4859C34.9609 37.8551 35.4696 38.0625 36 38.0625ZM36 22.3125C35.2667 22.3125 34.6667 22.9031 34.6667 23.625V30.1875C34.6667 30.9094 35.2667 31.5 36 31.5C36.7333 31.5 37.3333 30.9094 37.3333 30.1875V23.625C37.3333 22.9031 36.7333 22.3125 36 22.3125Z"
                        fill="#3F475C"
                        fillOpacity="0.3"
                      />
                    </svg>
                    <span>{defaultNoRecordFound}</span>
                  </div>
                )}
              </div>
            </Col>
          )}

          {permissions?.["OrderLineDetailTopProducts"] && (
            <Col xs={12} md={6}>
              <div className="content_body mincardheight ">
                <div className="heading">
                  <h2>TOP PRODUCTS</h2>
                  <div className="form_box">
                    {options && (
                      <Select
                        required
                        id="dropdown-products"
                        value={selectedProductOption}
                        defaultValue={{ value: "2", label: "Non-Returnable" }}
                        options={options}
                        onChange={(e) => handleSelectProductChange(e)}
                      />
                    )}
                  </div>
                </div>
                {productTotal > 0 && (
                  <BarChart
                    id={"top-products"}
                    data={dataTopProducts}
                    options={optionsTopProducts}
                  />
                )}
                {productTotal === 0 && (
                  <div className="no-records-wrapper mincardheight justify-content-center">
                    <svg
                      width="48"
                      height="42"
                      viewBox="0 0 48 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 5.25C0 2.3543 2.39167 0 5.33333 0H18.6667V10.5C18.6667 11.952 19.8583 13.125 21.3333 13.125H32V16.2914C25.8417 18.0059 21.3333 23.5758 21.3333 30.1875C21.3333 35.0355 23.7583 39.3176 27.475 41.9426C27.2083 41.9836 26.9417 42 26.6667 42H5.33333C2.39167 42 0 39.6457 0 36.75V5.25ZM32 10.5H21.3333V0L32 10.5ZM36 18.375C39.1826 18.375 42.2348 19.6195 44.4853 21.8348C46.7357 24.0501 48 27.0546 48 30.1875C48 33.3204 46.7357 36.3249 44.4853 38.5402C42.2348 40.7555 39.1826 42 36 42C32.8174 42 29.7652 40.7555 27.5147 38.5402C25.2643 36.3249 24 33.3204 24 30.1875C24 27.0546 25.2643 24.0501 27.5147 21.8348C29.7652 19.6195 32.8174 18.375 36 18.375ZM36 38.0625C36.5304 38.0625 37.0391 37.8551 37.4142 37.4859C37.7893 37.1167 38 36.6159 38 36.0938C38 35.5716 37.7893 35.0708 37.4142 34.7016C37.0391 34.3324 36.5304 34.125 36 34.125C35.4696 34.125 34.9609 34.3324 34.5858 34.7016C34.2107 35.0708 34 35.5716 34 36.0938C34 36.6159 34.2107 37.1167 34.5858 37.4859C34.9609 37.8551 35.4696 38.0625 36 38.0625ZM36 22.3125C35.2667 22.3125 34.6667 22.9031 34.6667 23.625V30.1875C34.6667 30.9094 35.2667 31.5 36 31.5C36.7333 31.5 37.3333 30.9094 37.3333 30.1875V23.625C37.3333 22.9031 36.7333 22.3125 36 22.3125Z"
                        fill="#3F475C"
                        fillOpacity="0.3"
                      />
                    </svg>
                    <span>{defaultNoRecordFound}</span>
                  </div>
                )}
              </div>
            </Col>
          )}
        </Row>
      </div>

      {/* <Row>
         <Col className="card bg-white m-2 content_body">
          <Row className="d-flex justify-content-between align-items-center">
            <Col sm={6} className="chart-header text-nowrap">
              <h3></h3>
            </Col>
            <Col sm={6} className="d-flex justify-content-end">
              <div>
                <select id="dropdown-manufacturer" value={selectedOption} onChange={handleSelectChange}>
                  <option value="1">Returnable</option>
                  <option value="2">Non-Returnable</option>
                </select>
              </div>
            </Col>
          </Row>
          { manufacturerTotal > 0 &&
          <BarChart id={'top-manufacturers'} data={dataTopManufacturers} options={optionsTopManufacturers} />
        </Col> 
        <Col className="card bg-white m-2 content_body">
          <Row className="d-flex justify-content-between align-items-center">
            <Col sm={6} className="chart-header text-nowrap">
              <h3>TOP PRODUCTS</h3>
            </Col>
            <Col sm={6} className="d-flex justify-content-end ">
              <div>
                <select id="dropdown-products" value={selectedProductOption} onChange={handleSelectProductChange}>
                  <option value="1">Returnable</option>
                  <option value="2">Non-Returnable</option>
                </select>
              </div>
            </Col>
          </Row>
          {productTotal >0 && 
          <BarChart id={'top-products'} data={dataTopProducts} options={optionsTopProducts} />
          }
          { productTotal === 0 &&
            <span>Filtered records do not include selected values</span>
          }
        </Col>
      </Row> */}
      <Row>
        <Col>
          <div className="content_body grid-reset">
            <GridComponent
              ref={myRef}
              gridRef={gridRef}
              rowData={gridData.rows}
              colDefs={colDefs}
              onGridReady={onGridReady}
              popupSave={popupSave}
              paginationPageSize={gridData.pageSize}
              paginationPageSizeSelector={gridData.rowsPerPageOptions}
              shortOrder={gridData.sortOrder}
              sortChange={onSortChanged}
              globalSearch={globalSearch}
              onExport={onExport}
              gridData={gridData}
              setGridData={setGridData}
              defaultColDef={defaultColDef}
              onClearFilter={onClearFilter}
              paginationChange={paginationChange}
              autoSizeStrategy={{
                type: "fitCellContents",
                colIds: [
                  "orderNumber",
                  "pickupDate",
                  "receivedDate",
                  "processDate",
                  "ndc",
                ],
              }}
            ></GridComponent>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default OrderLineDetails;
